.layout {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.header {
  min-height: 70px;
  background: white;
  display: grid;
  grid-template-columns: 40% 60%;
  font-size: 15px;
  font-family: "Times New Roman", Times, serif;
  color: rgb(92, 78, 78);
  border-bottom: 1px solid rgba(226, 223, 219, 0.6);
  position: sticky;
  top: 0;
  z-index: 30;
}
.headerTop {
  width: 100%;
  background: rgb(234, 234, 234);
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgb(82, 113, 131);
  font-size: 12px;
}
.headerTopleft {
  padding-left: 50px;
  color: rgb(159, 157, 157);
}
.headerTopright {
  padding-right: 50px;
  font-size: 12px;
}
.headerTopright a {
  color: rgb(159, 157, 157);
}
.headerTopright a:hover {
  color: rgb(235, 232, 232);
}
.headerTopright {
  display: flex;
  gap: 15px;
}
@media only screen and (max-width: 780px) {
  .headerTop {
    justify-content: center;
  }
  .headerTopleft {
    display: none;
  }
  .headerTopright span {
    display: none;
  }
}
.headerMinimized {
  min-height: 70px;
  background: rgb(244, 242, 242, 0.3);
  display: grid;
  grid-template-columns: 40% 60%;
  font-size: 20px;
  color: rgb(92, 78, 78);
  border-bottom: 1px solid rgba(226, 223, 219, 0.6);
  /* border-bottom:5px solid rgba(207, 172, 147, 0.7); */
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 2s;
}
.logoFrame {
  display: flex;
}
.logoFrame img {
  /* width:80px; */
  height: 65px;
}

.logoCaption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoCaptionText1 {
  color: rgb(218, 96, 9);
  font-weight: 900;
  font-size: 40px;
  letter-spacing: 9px;
  animation: fadeIn 5s, flash 600ms ease infinite alternate;
}
.logoCaptionText2 {
  font-size: 14px;
  font-weight: 600;
  color: rgb(53, 84, 95);
  font-family: monospace;
  animation: fadeIn 5s, flash 600ms ease infinite alternate;
}
.hide a,
.dropdownmenu a {
  text-decoration: none;
  color: rgb(92, 95, 65);
}

.headermenu:hover,
.hide a:hover,
.dropdownmenu a:hover {
  cursor: pointer;
  color: rgb(108, 109, 57);
}
.hide {
  display: flex;
}
.dropdownmenu {
  /* display:block; */
  position: absolute;
  height: 100vh;
  background: rgb(255, 255, 255);
  min-width: 70%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  animation: fade 1s ease-in-out;
  right: 0;
  top: 70px;
  padding: 10px;
}

.menuicon button {
  height: 40px;
  width: 40px;
  font-size: 30px;
  background: none;
  border: none;
  color: rgb(83, 83, 83);
}
.dropdown span {
  font-size: 25px;
}
.dropdown {
  display: flex;
  flex-direction: column;
  color: rgb(92, 91, 49);
  gap: 10px;
}
.main {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 2px;
  min-height: 100%;
}
.row {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
  /* padding:10px; */
}

.parallaxrow {
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100vw;
  gap: 120px;

  background-image: linear-gradient(
      rgba(249, 253, 230, 0.85),
      rgba(253, 253, 247, 0.9)
    ),
    url("../resources/images/nurse2.jpg");
  background-color: #473232;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.parallaxbutton {
  width: 150px;
  height: 40px;
  background: #ab7c2c;
  color: rgb(234, 229, 229);
  border: none;
  font-size: 15px;
  font-weight: 550;
}
.parallaxbutton:hover {
  opacity: 0.7;
  cursor: pointer;
  background: #a6781d;
  color: rgb(234, 229, 229);
}
.rowcard {
  height: 500px;
  width: 49%;
  border: 1px solid rgba(235, 232, 232, 0.2);
}
.parallaxcard {
  height: auto;
  width: 49%;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.referrals {
  border: none;
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 50px;
  border: 1px solid rgb(168, 180, 191, 0.3);
  border-radius: 150px 30px 150px 5px;
  background: transparent;
  box-shadow: 0px 0px 15px 0px #d5d3d3;
}
.referralsText {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 90%;
}

.referrals button:hover {
  opacity: o.4;
  background: rgb(82, 104, 119);
}

.referralsText .textupper {
  font-size: 30px;
  color: rgb(69, 119, 163);
  animation: 3s anim-flipX ease infinite;
}
.referralsText .textLower {
  font-size: 32px;
  font-weight: 700;
  color: rgb(104, 127, 147);
  animation: 4s anim-flipX ease infinite;
}
.rating {
  display: flex;
  flex-direction: column;
}

.rateHeading {
  color: rgb(140, 140, 140);
  font-size: 30px;
  font-weight: 600;
}
.parallaxcard:hover {
  /* opacity: 0.9; */
}
.carousel {
  height: 800px;
  overflow: hidden;
  animation: fade 1.5s ease-in-out;
}

.frame {
  height: 400px;
  width: 60%;

  /* padding-bottom: 100px; */
  display: flex;
}

.frameLeft,
.frameRight {
  width: 50%;
}
.frameRight {
  /* border:1px solid blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  font-family: "Times New Roman", Times, serif;

  height: 80%;
}
.frame_text {
  font-size: 16px;
  color: rgb(40, 70, 96);
  font-weight: 500;
  font-weight: 540;
  font-family: serif;
  padding: 15px 0 15px 8px;
}
.fa_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgb(237, 235, 235, 0.6);
  width: 380px;
  border-radius: 0 0 75px 5px;
  padding: 15px 0 15px 8px;
}
.fa a {
  text-decoration: none;
  color: rgb(107, 69, 25);
  font-size: 18px;
  font-style: oblique;
}
.frameLeft {
  height: 80%;
}

.frameLeftImg {
  width: 100%;
  height: 100%;
}

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
.wordcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.parallaxwordcontainer {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 100%;
  animation: flash 600ms ease infinite alternate;
}

.parallaxtextcontent {
  max-width: 300px;
  display: flex;
  flex-direction: column;
}
.parallaxtextcontent2 {
  width: 500px;
  display: flex;
  flex-direction: column;
}
.parallaxtextcontent3 {
  width: 700px;
  display: flex;
  flex-direction: column;
}

.actionButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 12vw;
  padding:7px;
  border: none;

  background:green;
  border-radius: 15px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  color: white;
}
.actionButton:hover {
  opacity: 0.9;
}

.actionImg {
  height: 20vw;
  color: red;
}
.parallaxwordtitle {
  font-family: "Times New Roman", Times, serif;
  font-size: 25px;
  font-weight: 600;
  color: rgb(67, 45, 17);
  min-width: 20vw;
}
.parallaxwordcontent {
  font-family: "Times New Roman", Times, serif;
  font-size: 16px;
  font-weight: 400;
  color: rgb(57, 59, 80);
  /* text-align: center; */
  line-height: 27px;
}
.parallaxwordcontent:hover,
.parallaxwordtitle:hover {
  opacity: 0.9;
}
.parallaxfonticon {
  font-size: 45px;
  color: rgb(194, 185, 63);
  /* color:#b98a3a; */

  height: 100px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite ease-in-out alternate,
    flash 500ms ease infinite alternate;
}

.parallaxRowContents {
  width: 90vw;
  display: flex;
}

@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
}

@keyframes pulse {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.parallaxfonticon:hover {
  color: rgb(194, 185, 63, 0.7);

  font-size: 47px;
}
.wordtitle {
  font-family: Georgia, serif;
  font-size: 16px;
  font-weight: 400;
  color: rgb(186, 114, 32);
}
.wordcontent {
  font-family: DejaVu Sans Mono, monospace;
  font-size: 27px;
  font-weight: 400;
  color: rgb(176, 61, 61);
}

.wordtitle:hover,
.wordcontent:hover {
  opacity: 0.9;
}
.rowparagraphtitle {
  font-family: Verdana, sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  font-style: normal;
  font-size: 30px;
  border-bottom: 1px solid rgb(157, 154, 154);
  color: rgb(111, 135, 166);
  padding-bottom: 30px;
}
.rowparagraphcontent {
  font-family: Verdana, sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 30px;
  font-style: normal;
  font-size: 18px;
  color: rgb(51, 42, 42);
  text-align: center;
}
.rowparagraph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 80%;
  color: black;
}
.footer {
  height: auto;
  background: rgb(49, 49, 55);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 4%;
  padding-right: 4%;
}
.footerUpper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  width: 100%;
}

.footerLower {
  display: flex;

  justify-content: space-between;
  align-items: center;

  padding-bottom: 5%;
}
.footercards {
  max-width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.footerBundle {
}

.footerSub {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 12px;
  opacity: 0.7;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid rgb(208, 205, 205, 0.5);
}
.footercardslarge {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footercardstitle {
  color: rgb(255, 255, 255);
  width: 100%;
  padding-bottom: 20px;
  font-size: 25px;
  text-align: left;
  font-weight: 600;
}
.footercardsbody {
  display: flex;
  line-height: 20px;
  flex-direction: column;
  font-size: 13px;
  gap: 15px;
}
.footercolumns {
  display: flex;
  gap: 8px;
}

.footer_infos {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.footercolumns_infos {
  display: flex;
  gap: 40px;
}
.columndivisions {
  line-height: 30px;
  font-size: 15px;
}
.footerparagraph {
  color: rgb(189, 186, 186);
  font-size: 13px;
  border-bottom: 1px solid rgb(179, 177, 177, 0.2);
}
.footerparagraph:hover {
  cursor: pointer;
  color: rgb(194, 151, 151);
}
.square {
  height: 100%;
  padding-left: 2%;
  padding-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  font-size: 17px;
  /* color:"#b98a3a"; */
  color: green;
}

.headerButton {
  /* background:rgb(146, 139, 40); */
  background: green;
  /* background:#b98a3a; */
  border: none;
  width: 200px;
  height: 40px;
  border-radius: 19px;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  animation: fadeIn 5s, flash 600ms ease infinite alternate;
  /* transition: transform .2s; */
}
.eventFrame {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 10vh;
  /* padding-bottom: 2vh; */

  width: 90%;
}
.eventName {
  width: 70%;
  font-size: 25px;
  font-family: sans-serif;
  font-weight: 600;
  color: green;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  gap:10px;
}
.eventDesc {
  display: flex;
  margin-top: 20px;
  align-items: center;
  gap:5px;
  

}
.rowCase{
  
  width:90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left:12vw;
  overflow-x: hidden;
  padding-bottom: 2vw;

}
.rowCaseHeader{
  width:100%;
  font-weight: 700;
  color: rgb(67, 45, 17,0.8);
  font-family: "Times New Roman", Times, serif;
  font-size: 25px;
  font-weight: 600;
  
}

.rowCaseText2{
width:33%;
}
.rowCaseImg{
  
  /* background:blue; */
  /* height:40vh; */
}
.rowCaseHeader span{
  border-bottom: 1px solid rgb(67, 45, 17,0.2);
  padding-bottom:5px;
  padding-right:100px;
  
}
.rowCaseHeader .headerStyle1 {
  border-bottom:1px solid rgb(146, 139, 40, 0.2);
  
}
.headerStyle1{
  display: flex;
  flex-direction: column;
  widtH:55%;
  padding-right:none;

}
.headerStyle2{
  background:rgb(146, 139, 40);
  color:white;
  padding:4px 4px 4px 4px;
  font-size: 16px;
  font-weight: 500;
  width:99%;
  padding-right: 0;

  
}
.headerStyle3{
  color:rgb(146, 139, 40);
  font-size: 11px;
  padding-top:10px;

}

.rowCaseContent2{
 
  width:100%;
  color:rgb(146, 139, 40,0.8);
  line-height: 1.3;
  font-family: sans-serif;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  gap:1vw;

}
.rowCaseContent2 li{
  padding-bottom: 8px;
}
.rowCaseContent{
 
  width:100%;
  color:rgb(146, 139, 40,0.9);
  line-height: 1.8;
  font-family: sans-serif;
  display: flex;
  justify-content: flex-start;
  font-size: 17px;
  gap:7vw;

}

.eventDesc span{
  color: rgb(146, 139, 40);
  font-weight: 500;
  font-size: 17px;
}

.eventDesc .eventText{
  border-bottom: 1px solid rgb(199, 189, 50,0.5);
  border-top: 1px solid rgb(199, 189, 50,0.5);
  line-height: 1.8;
}
.eventDesc .eventText2 span{
background:rgb(146, 139, 40);
color:white;
padding:7px;
border-radius: 10px;
}
.eventDesc .eventTxtLogo span{
  color:rgb(146, 139, 40,0.7);
font-size: 20px;
}
.eventDesc .eventTxtLogo2 span{
  
  font-size: 20px;
  color:rgb(146, 139, 40,0.7);
  }
.eventText{
  display: flex;
  flex-direction: column;
}
  .eventImage{
width:34vw;
  }
  .eventTitle{
    display: flex;
    flex-direction: column;
  }

/* .eventDesc .eventTxt {
  color: "rgb(146, 139, 40)";
  font-weight: 500;
  font-size: 15;

  border-bottom: "1px solid rgb(199, 189, 50,0.5)";
  border-top: "1px solid rgb(199, 189, 50,0.5)";
} */
.headerButton:hover {
  opacity: 0.9;
  background: rgb(51, 155, 56);
}
.headerleft {
  padding-left: 5%;
  display: flex;
  align-items: center;
}
.headermiddle {
  display: flex;
  align-items: center;
  /* justify-content:flex-end; */
  justify-content: center;
}
.headerright {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.button {
  width: 140px;
  height: 40px;
  color: white;
  background: rgb(189, 35, 35);
  border: none;
}
.button:hover {
  opacity: 0.9;
  cursor: pointer;
}

.square:hover {
  cursor: pointer;
  /* background:rgb(237, 232, 232); */
  color: rgb(141, 94, 32);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 20px;
}
.logotext {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logo:hover {
  opacity: 0.7;
  cursor: pointer;
}

.logoup {
  font-family: "Marker Felt", fantasy;
  font-style: Bold;
  font-variant: small-caps;
  font-weight: 900;
  color: rgb(147, 20, 20);
}
.logodown {
  font-family: "Lucida Handwriting", cursive;
}
.footlogoup {
  font-family: "Marker Felt", fantasy;
  font-style: Bold;
  font-variant: small-caps;
  font-weight: 900;
  color: rgb(253, 251, 251);
}
.footlogodown {
  font-family: "Lucida Handwriting", cursive;
  color: white;
}
.logofooter {
  width: 100%;
  text-align: center;
  color: rgb(223, 219, 219);
  font-weight: 500;
  font-family: "Impact", fantasy;
  font-style: bold;
  font-size: 18px;
  cursor: pointer;
}

.footer img {
  float: left;
}

.logofooter:hover {
  opacity: 0.7;
}
.footeraddress {
  width: 100%;
  text-align: justify;
  color: rgb(222, 218, 218);
}
.footeraddress:hover {
  opacity: 0.7;
}
.footerdescription {
  width: 100%;
  word-wrap: break-word;
  text-align: justify;

  color: rgb(222, 218, 218);
  line-height: 25px;
}
.footerdescription:hover {
  opacity: 0.7;
}

.menuicon {
  display: none;
}

.socialmedia a {
  color: rgb(159, 157, 157);
  font-size: 30px;
}
.socialmedia a:hover {
  color: rgb(235, 232, 232);
}
.socialmedia {
  display: flex;
  gap: 15px;
}

.aboutus {
}

.overlay {
  position: absolute;

  width: 100%;
  height: 220%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 2;
  display: flex;
  justify-content: center;
}

.carouselContent {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  animation: flash 600ms ease infinite alternate;
  font-family: "Times New Roman", Times, serif;
}
.carouselLinks {
  font-family: monospace;
  font-size: 20px;
  color: rgb(212, 152, 109);
}
.carouselButton button {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  /* background:#a0752b; */
  background: rgb(94, 89, 23);
  color: white;
  text-align: center;
  cursor: pointer;
}
.carouselButton button:hover {
  opacity: 0.9;
}
.carouselText1 {
  color: white;
  font-size: 50px;
  font-weight: 900;
  /* width: 1000px; */
  font-style: bold;
  font-family: "Times New Roman", Times, serif;
  /* font-family: Lucida Sans; */
}
.carouselText2 {
  font-size: 25px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  font-family: "Times New Roman", Times, serif;
}
.rowContent {
  width: 100%;
  display: flex;
  overflow: hidden;

  /* background:blue; */
}
.rowTitle {
  font-size: 22px;

  font-weight: 700;
  color: grey;
  text-align: center;
}
.rowParagraph {
  color: rgb(103, 102, 102);
  font-size: 14px;
  font-family: "Times New Roman", Times, serif;
}
.rowContentLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding:10px 0 10px 0; */
  transition: transform 0.2s;
}

.rowContentSpan {
  font-style: bold;
  font-weight: 700;
  font-size: 18px;
  padding-top: 5px;
}
.rowContentRight {
  width: 60%;
  /* overflow: hidden; */
}
.slogan {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 10px 0 10px 0;
  width: 100%;
  margin-top: 5px;
}

.sloganLogo {
  width: 30px;
}

.sloganText {
  font-size: 17px;
  font-weight: 700;
  border-top: 1px solid rgb(93, 143, 166, 0.5);
  border-bottom: 1px solid rgb(93, 143, 166, 0.5);
  color: rgb(37, 145, 164);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  padding-right: 10px;
}

.sloganTextEdge {
  height: 100%;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 40px solid rgb(206, 223, 239);
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rowHeading {
  padding-top: 5px;
  font-size: 35px;
  font-weight: 700;
  border-bottom: 1px solid rgb(226, 224, 224);
  color: rgb(25, 65, 91);
  text-align: center;
  width: 40%;
  /* animation: my-animation 5s ; */
}
.rowHeadingCase {
  padding-top: 40px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowContent2 {
  width: 100%;
  display: flex;
  flex-direction: column;

  gap: 20px;
}

.rowContent2Right {
}

.rowContent2Left {
  /* flex-direction: column; */
}
.contents {
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* animation: my-animation 5s ; */
}

.why_us_contents {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px 0 50px 0;
}
.why_us_contents li {
  text-align: left;
  padding-bottom: 8px;
}
.services {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 200px;

  color: rgb(87, 62, 47);
  font-size: 15px;

  padding-top: 10px;
  /* border:none; */
  border: 1px solid rgb(255, 255, 255, 0.8);
  background: transparent;
  font-family: "Times New Roman", Times, serif;

  border-radius: 5px;
  animation: fadeIn 10s, flash 1100ms ease infinite alternate;
  transition: transform 0.2s;

  height: auto;
}

.serviceImageContainer {
  height: 50%;

  width: 100%;
}
.serviceImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
}
.serviceContent {
  height: 50%;
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.serviceContentHeader {
  /* width:100%; */

  text-align: left;
  font-weight: 600;

  font-size: 18px;
  color: rgb(32, 61, 84);
}
.ServiceRowContents {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width:70%;

  align-items: flex-start;
}
.contentDivide {
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.serviceContentText {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 7px;
  width: 100%;
  height: auto;
  font-size: 20px;
}

.serviceContentText span {
  font-size: 12px;
  color: rgb(97, 93, 32);
}

.services:hover {
  background: rgb(251, 251, 251, 0.3);
  transform: scale(1.02);
}
.fa_icons {
  width: 100%;
  font-size: 70px;
  text-align: left;
}

.fa_icons img {
  height: 100px;
}
.services i {
  color: rgb(173, 172, 172);
}
.textUpper,
.textLower {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.textUpper i {
  font-size: 34px;
  text-align: center;
  color: rgb(248, 248, 248);
}

.textUpper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(154, 139, 127);
  background: rgb(218, 123, 73);
  width: 15%;
}
.textLower {
  color: rgb(67, 45, 17);

  font-size: 16px;
  font-style: bold;
}
.textLower2 {
  color: rgb(118, 77, 27);

  font-size: 13px;
  font-style: bold;
}
.contentRight {
  width: 30%;
  padding: 20px;
  padding-top: 30px;
  background: rgb(250, 250, 250);
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}
.contentLeft {
  width: 60%;
  padding: 20px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.rowHeading2 {
  color: rgb(168, 168, 168);
  font-size: 25px;
  font-weight: 500;
  text-align: center;
}
.rowHeading3 {
  color: rgb(168, 168, 168);
  font-size: 24px;
  font-weight: 500;
  border-bottom: 1px dashed rgb(224, 223, 223);
}
.contentLeftContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.applicationForms {
  width: 100%;
  display: flex;
  justify-content: flex-start;

  background: transparent;
  border: none;
  border-radius: 5px;
  /* cursor:pointer; */
  animation: fadeIn 5s, flash 600ms ease infinite alternate;
  transition: transform 0.2s;
}
.applicationFormHeading {
  font-size: 25px;
  font-weight: 700;
  color: rgb(81, 97, 130);
  padding: 10px 0 10px 30px;
}
.application {
  display: flex;
  flex-direction: column;
}
.applicationForms .textlower2 {
  width: 60%;
  font-size: 15px;
  text-align: left;
  font-family: "Times New Roman", Times, serif;
}

.applicationForms .textupper {
  width: 10%;
  padding: 5px;
  font-size: 15px;
  color: rgb(63, 143, 180);
}
.applicationFormElastic {
  /* background:rgb(255, 255, 255); */
  padding: 5px;
  color: rgb(96, 75, 75);
  /* letter-spacing: 7px; */
  font-weight: 800;
  /* animation:  fadeIn 5s, flash 600ms ease infinite alternate; */
  animation: fadeIn 5s, flash 600ms, anim-popoutin 8s ease infinite;
}
.applicationButtonContainer {
  border: 1px solid rgb(69, 105, 159, 0.2);
  border-radius: 2px 2px 30px 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.applicationButton {
  border: none;
  box-shadow: 0px 0px 5px 0px #888888;
  background: rgb(69, 105, 159);
  width: 300px;
  height: 40px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  color: white;
}
.rateCaptions {
  width: 90%;

  color: rgb(197, 123, 66);
  font-size: 20px;
  letter-spacing: 5px;
  font-weight: 800;
  padding-top: 10px;
  animation: 1s anim-flipX ease infinite;
  /* animation:  fadeIn 5s, flash 600ms ease infinite alternate; */
}

@keyframes anim-flipX {
  0% {
    opacity: 0;
    transform: rotateX(90def);
  }
  50% {
    opacity: 1;
    transform: rotateX(720deg);
  }
  100% {
    /* animate nothing to pause animation at the end */
    opacity: 1;
    transform: rotateX(720deg);
  }
}
@keyframes flash {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.9;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
@keyframes waviy {
  0%,
  40%,
  100% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-20px);
  }
}

.lineUp {
  animation: 2s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/* subtle zoom to attention and then back */
/* .pop-outin {
    animation: 2s anim-popoutin ease infinite;
  } */

@keyframes anim-popoutin {
  0% {
    transform: scale(0);
    opacity: 0;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  25% {
    transform: scale(0.9);
    opacity: 1;
    text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
  }
  50% {
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    /* animate nothing to add pause at the end of animation */
    transform: scale(1);
    opacity: 1;
    text-shadow: 1px 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframse elastic {
  0% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}
.applicationForms:hover {
  /* background:rgb(236, 236, 236); */
  /* transform: scale(1.05); */
}

/* 5 start ratings */

.rate {
  float: left;
  /* height: 46px; */
}
.rate:not(:checked) > input {
  position: absolute;
  /* top:-9999px; */
}
.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 50px;
  color: #ccc;
}
.rate:not(:checked) > label:before {
  content: "★ ";
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

/* Modified from: https://github.com/mukulkant/Star-rating-using-pure-css */

@keyframes my-animation {
  from {
    opacity: 0;
    -moz-transform: translateY(20%);
    -webkit-transform: translateY(20%);
    transform: translateY(20%);
  }
  to {
    -moz-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    opacity: 1;
  }
}

.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  display: flex;
}

.carousel {
  height: 500px;
  /* border-bottom:5px solid rgb(218, 108, 29); */
  overflow: hidden;
}

.formHeight {
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form {
  height: auto;
  width: auto;
  padding: 15px;
  border-top: 1px solid rgb(225, 222, 222, 0.6);
  border-bottom: 1px solid rgb(225, 222, 222, 0.6);
}

.sent_application_detail {
  padding: 40px;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  color: rgb(74, 211, 74);
}
.sent_application_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.sent_application_button button {
  width: 200px;
  padding: 10px;
  background: transparent;

  color: rgb(72, 126, 161);
  font-size: 20px;
  border: 1px solid grey;
  border: none;
  cursor: pointer;
}

.sent_application_button button:hover {
  background: rgb(200, 201, 202, 0.2);
}

.formHeight {
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
}
.form {
  background: rgb(244, 244, 244);
  padding: 40px;
  width: 30%;
  text-align: center;
}

.formHeader {
  color: rgb(204, 133, 75);
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  width: 100%;
  padding-bottom: 30px;
  text-decoration: underline;
}

.comment {
  display: flex;
  flex-direction: column;

  height: 200px;
  width: 300px;
  gap: 10px;
}
.commentTitle {
  color: grey;
  font-weight: 500;
  font-size: 20px;
}
.commentForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.commentForm textarea {
  height: 100px;
  width: 100%;
  outline: none;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form form {
  width: 100%;

  gap: 10px;
}
form .inputGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.inputGroup label {
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-family: "Times New Roman", Times, serif;
}
.inputGroup input,
.inputGroup select,
.inputGroup textarea {
  width: 400px;
  height: 34px;
  outline: none;
  border: 1px solid rgb(198, 195, 195);
}

.inputGroup textarea {
  height: 150px;
}

.inputGroup button {
  width: 200px;
  height: 34px;
  outline: none;
  background: rgb(147, 83, 19);
  color: white;
  font-weight: 700;
  font-size: 20px;
  border: none;
  cursor: pointernn;
}

.response {
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 40vw;
  justify-content: center;
  align-items: center;
  gap: 5px;

  border: 1px solid rgb(172, 238, 172);
}
.response_fa {
  font-size: 100px;
  color: green;
}
.response_message1 {
  font-size: 20px;
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  color: green;
}
.response_message2 {
  font-size: 17px;
  font-family: "Times New Roman", Times, serif;
}

.return_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.return_button button {
  color: rgb(138, 73, 7);
  background: none;
  border-radius: 5px;
  box-shadow: none;
  font-family: "Times New Roman", Times, serifnn;
}
.return_button button:hover {
  background: rgb(238, 237, 237);
}

.feedbackTab {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.commentTitle {
  width: 100%;
  font-family: "Times New Roman", Times, serif;
}

.afterfeedbackTab {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(9, 210, 9);
  font-size: 50px;
  font-family: "Times New Roman", Times, serif;
  text-align: center;
}

@media only screen and (max-width: 780px) {


  .eventFrame{
    flex-direction: column;
    
  }
  .eventName{
    flex-direction: column;
    font-size:22px;
    line-height: 2;
    
    width:100%;
  }
  .eventTitle{
  display:inline-block;
  }
  .rowCaseHeader span{
 
    font-size: 20px;

  }
  .rowCaseHeader{
 
    padding-right: 5vw;
  }
  .rowCase{
    /* padding-right: 5vw; */
    /* background:blue; */
    padding-bottom: 5vw;
  }
.eventImage{

  width:100%;
  margin-top: 4vh;
}
.eventText{
  display: inline-block;
}

.rowCaseContent{
  margin-top: 5vh;
  flex-direction: column-reverse;
}
.rowCaseContent2{
  margin-top: 3vh;
  flex-direction: column;

}
.rowCaseText2{
font-size: 17px;
  width:95%;
}

.headerStyle2{

width:86vw;
  font-size: 14px !important;
  padding-right:0 !important;
  

}
.headerStyle1{
  padding:0;
  margin:0;
}

  .carouselText1 {
    font-size: 18px;
    font-weight: 500;
    /* width: 50px; */

    padding-left: 4px;
    padding-right: 4px;
  }

  .actionButton {
    width: 100%;
  }
  .carouselText2 {
    font-size: 10px;
    font-weight: 700;
  }

  .rowTitle {
    text-align: center;
  }
  .carouselButton button {
    background: rgb(94, 89, 23);
    margin-top: 10px;
    width: 130px;
    height: 30px;
  }
  .carouselContent {
    margin-top: 40px;
    /* gap:10; */
  }
  .carousel {
    height: 210px;
    border-bottom: 5px solid rgb(185, 156, 112);
  }

  .headerleft {
    padding: 0;
  }

  .logoCaptionText1 {
    font-size: 30px;
  }
  .logoCaptionText2 {
    font-size: 9px;
  }
  .header,
  .headerMinimized {
    /* grid-template-columns: 50% 50%; */
    font-size: 18px;
  }

  .menuicon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 30px;
    /* margin-right: 10px; */
    cursor: pointer;
  }
  .headermiddle {
    display: none;
  }
  .menuicon:hover {
    opacity: 0.9;
    color: #978e8a;
  }

  .headerMinimized,
  .header {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: center;
  }

  .headerTopright span {
    display: block;
    /* width: 100%; */
    text-align: center;
  }

  .headerTop {
    padding: 0;
  }
  .headerTopright {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
    padding: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .headerTopright span a {
    font-size: 12px;
  }
  .headertop_fa {
    display: flex;
    gap: 20px;
  }
  .rowContentRight img {
    display: none;
  }
  .rowContent2 {
    margin: 0;
    padding: 0;
  }
  .contents {
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
  }
  .services {
    width: 100px;
  }
  .row {
    flex-direction: column;
    /* padding: 10px; */
  }
  .contentRight {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .rowHeading3,
  .rowHeading {
    text-align: center;
    font-size: 22px;
  }
  .contentLeft {
    width: 100%;
    padding: 0;
    padding-top: 40px;
    justify-content: center;
  }
  .fa_icons {
    text-align: center;
  }
  .rowParagraph {
    text-align: center;
  }
  .lineUp {
    text-align: center;
    font-size: 12px;
  }
  .contentLeftContent {
    width: 100%;
  }
  .applicationForms {
    width: 48%;
  }
  .rowContent {
    flex-direction: column;
  }

  .footer {
    width: 100%;
    padding: 0;
    height: auto;
  }
  .footerUpper {
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .socialmedia {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .socialmedia i {
    font-size: 40px;
    padding: 0;
  }

  .footerLower {
    width: 100%;

    flex-direction: column;
    gap: 50px;
  }
  .footercards {
    max-width: 100%;
    padding: 0;
  }
  .footercardstitle {
    text-align: center;
    padding: 0;
  }
  .aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerdescription {
    text-align: center;
    width: 95%;
  }
  .footerSub {
    /* padding-top:20px;
padding-bottom: 20px; */
    /* gap:20px; */
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .footerSub span {
    text-align: center;
  }

  .why_us_contents {
    display: grid;

    height: auto;
    
  }
  .why_us_contents li {
    text-align: left;
    padding-bottom: 8px;
  }

  .parallaxtextcontent,
  .parallaxtextcontent2,
  .parallaxtextcontent3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0;
  }
  .parallaxwordcontainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;
    padding: 0;
  }
  .parallaxwordcontent {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
  }
  .parallaxwordtitle {
    text-align: center;
    padding: 2px;
  }
  .frame {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }
  .frameLeft,
  .frameRight {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .frame_text {
    text-align: center;
    font-size: 14px;
  }
  .frameRight {
    padding-top: 30px;
    gap: 30px;
  }
  .fa_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 0 0 60px 60px;
  }
  .rowHeadingCase {
    width: 100%;
    padding: 0;
  }
  .rowHeading {
    width: 100%;
    text-align: center;
  }

  .contentDivide {
    width: 100%;
    /* gap: 0; */
  }

  .ServiceRowContents {

    width:70vw;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4;
    padding: 0;
  }

  .services {
    width: 90%;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-bottom: 3vh;
    gap: 0;
    margin: 0;
  }
  .serviceContent {
    padding: 0;
  }
  .serviceContentText {
    text-align: left;
    width: 100%;
    font-family: sans-serif;
  }
  .rowContent2 {
    width: 100%;
  }
  .parallaxrow {
    padding: 0;
    gap: 0;
    width: 100%;
  }

  .parallaxPad {
    display: flex !important;
    flex-direction: row !important;

    background: red;
  }
  .parallaxRowContents {
    flex-direction: column;
    height: auto;
    width: 100%;

    padding: 0;
    gap: 10px;
  }
  .parallaxcard {
    /* width:100% !important; */
    /* background:yellow; */
    width: 100% !important;
    padding: 0;

    height: auto !important;
  }
  .referrals {
    width: 100%;

    padding: 10px;
    height: auto;
    border-radius: 5px 5px 25px 25px;
  }
  .rating {
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 0;
  }
  .rating .rateHeading {
    width: 100%;
    text-align: center;
    padding: 0;
    gap: 0;
  }

  .applicationButton {
    width: 90%;
    padding: 0;
    gap: 0;
  }
  .parallaxCardImg {
    width: 100%;
    padding: 0;
    gap: 0;
  }
  .application {
    width: 100%;
  }
  .applicationFormHeading {
    width: 100%;

    padding: 0;
    text-align: center;
  }
  .applicationForms {
    width: 100%;
  }
  .applicationButtonContainer {
    padding: 0;
    padding-top: 10px;
  }
}
